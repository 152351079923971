import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";

function Mention({data}) {
    return (
        <div className="podcast_story p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="m-auto text-center">
                        <h5 className="episode_name">
                            Mentioned
                        </h5>
                        <Row className="h-100">
                            {data?.mentioned_tabs?.map((value, i) => (
                                <Col xs={12} md={4} lg={3} xl={3} xxl={3}
                                     className="text-center m-auto img_podcast">
                                    <img
                                        src={`${mediaBaseURL}${value.image}`}
                                        className="img-fluid"
                                        alt="image_podcast"/>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Mention;